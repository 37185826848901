import React from 'react'

import '../styles/index.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Header from '../components/Header'
import Footer from '../components/Footer'
import CookieBanner from '../components/CookieBanner'

const SiteLayout = ({ header = 'light', children }) => {
  return (
    <>
      <Header type={header} />
      <main className="bg-white" style={{ overflowX: 'hidden' }}>
        {children}
      </main>
      <Footer />
      <CookieBanner />
    </>
  )
}

export default SiteLayout
