import React from 'react'
import cn from 'classnames'

import styles from './Bubbles.module.scss'

const Bubbles = ({ className }) => {
  return (
    <div className={cn(styles.bubbles, className)}>
      <span className={styles.one} />
      <span className={styles.two} />
      <span className={styles.three} />
      <span className={styles.four} />
      <span className={styles.five} />
      <span className={styles.six} />
    </div>
  )
}

export default Bubbles
