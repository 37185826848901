import React, { useState } from 'react'
import cn from 'classnames'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons'

import styles from './MobileMenu.module.scss'

const MobileMenuButton = ({ type = 'dark', isOpen, onClick }) => {
  const className = cn(
    styles.mobileMenuButton,
    styles.mobileMenuButtonCollapse,
    {
      [styles.isActive]: isOpen,
      [styles.dark]: type === 'dark',
      [styles.light]: type === 'light',
    }
  )
  return (
    <button className={className} onClick={onClick} type="button">
      <span className={styles.mobileMenuButtonBox}>
        <span className={styles.mobileMenuButtonInner} />
      </span>
    </button>
  )
}

const MobileMenu = ({ isOpen }) => {
  const [howItWorksOpen, setHowItWorksOpen] = useState(false)
  const [pricingOpen, setPricingOpen] = useState(false)
  const [toolsOpen, setToolsOpen] = useState(false)

  const className = cn(styles.mobileMenu, { [styles.isActive]: isOpen })
  const howItWorksCn = cn(styles.subNav, { [styles.isActive]: howItWorksOpen })
  const pricingCn = cn(styles.subNav, { [styles.isActive]: pricingOpen })
  const toolsCn = cn(styles.subNav, { [styles.isActive]: toolsOpen })

  return (
    <nav className={className}>
      <div className={styles.mobileMenuContainer}>
        <Link className={styles.mobileMenuLink} to="/">
          Home
        </Link>
        <button
          className={styles.mobileMenuLink}
          onClick={() => setHowItWorksOpen(!howItWorksOpen)}
          type="button"
        >
          How it works
          <FontAwesomeIcon
            icon={faChevronDown}
            rotation={howItWorksOpen ? 180 : undefined}
            size="1x"
          />
          <div className={howItWorksCn}>
            <Link className={styles.subNavLink} to="/how-it-works/start-ups">
              Start ups
            </Link>
            <Link
              className={styles.subNavLink}
              to="/how-it-works/small-businesses"
            >
              Small businesses
            </Link>
            <Link className={styles.subNavLink} to="/how-it-works/sole-traders">
              Sole traders
            </Link>
            <Link
              className={styles.subNavLink}
              to="/how-it-works/switching-accountants"
            >
              Switching accountants
            </Link>
          </div>
        </button>
        <button
          className={styles.mobileMenuLink}
          onClick={() => setPricingOpen(!pricingOpen)}
          type="button"
        >
          Pricing
          <FontAwesomeIcon
            icon={faChevronDown}
            rotation={pricingOpen ? 180 : undefined}
            size="1x"
          />
          <div className={pricingCn}>
            <Link
              className={styles.subNavLink}
              to="/pricing/small-business-accounting"
            >
              Small business accounting
            </Link>
            <Link
              className={styles.subNavLink}
              to="/pricing/standalone-services"
            >
              Standalone services
            </Link>
            <Link
              className={styles.subNavLink}
              to="/pricing/specialised-services"
            >
              Specialised services
            </Link>
            <Link
              className={styles.subNavLink}
              to="/pricing/build-your-package"
            >
              Build your package
            </Link>
          </div>
        </button>
        <button
          className={styles.mobileMenuLink}
          onClick={() => setToolsOpen(!toolsOpen)}
          type="button"
        >
          Tools & support
          <FontAwesomeIcon
            icon={faChevronDown}
            rotation={toolsOpen ? 180 : undefined}
            size="1x"
          />
          <div className={toolsCn}>
            <a
              className={styles.subNavLink}
              href="https://central.xero.com/s/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Xero Central
            </a>
            <a
              className={styles.subNavLink}
              href="https://help.receipt-bank.com/hc/en-us"
              target="_blank"
              rel="noopener noreferrer"
            >
              Receipt Bank support
            </a>
            <Link className={styles.subNavLink} to="/call-back">
              Request a call back
            </Link>
          </div>
        </button>
        <Link className={styles.mobileMenuLink} to="/contact-us">
          Contact us
        </Link>
      </div>
    </nav>
  )
}

export { MobileMenuButton, MobileMenu }
