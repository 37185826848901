import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import cn from 'classnames'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faRocket,
  faStore,
  faUser,
  faRandom,
  faBriefcase,
  faCalculator,
  faUserTie,
  faBallot,
  // faStar, Referrals
} from '@fortawesome/pro-light-svg-icons'

import Container from './Container'
import { MobileMenuButton, MobileMenu } from './MobileMenu'
import logo from '../images/logo/logo@1x.png'
import logo1x from '../images/logo/logo@1.5x.png'
import logo2x from '../images/logo/logo@2x.png'
import logo3x from '../images/logo/logo@3x.png'
import logoWhite from '../images/logo/logo-white@1x.png'
import logoWhite1x from '../images/logo/logo-white@1.5x.png'
import logoWhite2x from '../images/logo/logo-white@2x.png'
import logoWhite3x from '../images/logo/logo-white@3x.png'

import styles from './Header.module.scss'

const Header = ({ type }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [mobileMenu, setMobileMenu] = useState(null)
  const [isScrollTop, setIsScrollTop] = useState(true)

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY === 0 && !isScrollTop) {
        setIsScrollTop(true)
      } else if (window.scrollY !== 0 && isScrollTop) {
        setIsScrollTop(false)
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [isScrollTop])

  useEffect(() => {
    const target = document.querySelector('#mobile-menu')
    setMobileMenu(target)

    return () => clearAllBodyScrollLocks()
  }, [])

  useEffect(() => {
    if (mobileMenuOpen) {
      disableBodyScroll(mobileMenu)
    } else {
      enableBodyScroll(mobileMenu)
    }
  }, [mobileMenu, mobileMenuOpen])

  const headerCn = cn({
    [styles.header]: true,
    [styles.scrolled]: !isScrollTop,
    [styles.light]: type === 'light',
    [styles.dark]: type === 'dark',
  })

  return (
    <header className={headerCn}>
      <Container size="xl" className={styles.container}>
        <Link className={styles.logoContainer} to="/">
          {type === 'dark' || !isScrollTop ? (
            <img
              alt="AirAccounting logo"
              className={styles.logo}
              height={38}
              src={logo}
              srcSet={`${logo1x} 1.5x, ${logo2x} 2x, ${logo3x} 3x`}
              width={213}
            />
          ) : (
            <img
              alt="AirAccounting logo"
              className={styles.logo}
              height={38}
              src={logoWhite}
              srcSet={`${logoWhite1x} 1.5x, ${logoWhite2x} 2x, ${logoWhite3x} 3x`}
              width={213}
            />
          )}
        </Link>
        <nav className={styles.nav}>
          <Link to="/">Home</Link>
          <div className={styles.dropdown}>
            <button className={styles.dropdownTrigger} type="button">
              How it works
            </button>
            <div className={styles.dropdownMenu}>
              <Link
                className={styles.dropdownLink}
                to="/how-it-works/start-ups"
              >
                <span>
                  <FontAwesomeIcon
                    className={styles.arrow}
                    icon={faRocket}
                    size="1x"
                  />
                </span>
                Start ups
              </Link>
              <Link
                className={styles.dropdownLink}
                to="/how-it-works/small-businesses"
              >
                <span>
                  <FontAwesomeIcon
                    className={styles.arrow}
                    icon={faStore}
                    size="1x"
                  />
                </span>
                Small businesses
              </Link>
              <Link
                className={styles.dropdownLink}
                to="/how-it-works/sole-traders"
              >
                <span>
                  <FontAwesomeIcon
                    className={styles.arrow}
                    icon={faUser}
                    size="1x"
                  />
                </span>
                Sole traders
              </Link>
              <Link
                className={styles.dropdownLink}
                to="/how-it-works/switching-accountants"
              >
                <span>
                  <FontAwesomeIcon
                    className={styles.arrow}
                    icon={faRandom}
                    size="1x"
                  />
                </span>
                Switching accountants
              </Link>
            </div>
          </div>
          <div className={styles.dropdown}>
            <button className={styles.dropdownTrigger} type="button">
              Pricing
            </button>
            <div className={styles.dropdownMenu}>
              <Link
                className={styles.dropdownLink}
                to="/pricing/small-business-accounting"
              >
                <span>
                  <FontAwesomeIcon
                    className={styles.arrow}
                    icon={faBriefcase}
                    size="1x"
                  />
                </span>
                Small business accounting
              </Link>
              <Link
                className={styles.dropdownLink}
                to="/pricing/standalone-services"
              >
                <span>
                  <FontAwesomeIcon
                    className={styles.arrow}
                    icon={faCalculator}
                    size="1x"
                  />
                </span>
                Standalone services
              </Link>
              <Link
                className={styles.dropdownLink}
                to="/pricing/specialised-services"
              >
                <span>
                  <FontAwesomeIcon
                    className={styles.arrow}
                    icon={faUserTie}
                    size="1x"
                  />
                </span>
                Specialised services
              </Link>
              <Link
                className={styles.dropdownLink}
                to="/pricing/build-your-package"
              >
                <span>
                  <FontAwesomeIcon
                    className={styles.arrow}
                    icon={faBallot}
                    size="1x"
                  />
                </span>
                Build your package
              </Link>
            </div>
          </div>
          <Link to="/contact-us">Contact us</Link>
        </nav>
        <MobileMenuButton
          type={type === 'light' && isScrollTop ? 'light' : 'dark'}
          isOpen={mobileMenuOpen}
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        />
      </Container>
      <MobileMenu id="mobile-menu" isOpen={mobileMenuOpen} />
    </header>
  )
}

export default Header
