import { useCallback } from 'react'

const useLocalStorage = key => {
  const getValue = useCallback(() => {
    try {
      // window isn't defined in Gatsby build process
      if (typeof window !== 'undefined') {
        const item = window.localStorage.getItem(key)
        return item ? item : null
      } else {
        return null
      }
    } catch (err) {
      throw err
    }
  }, [key])

  const setValue = useCallback(
    value => {
      try {
        if (typeof window !== 'undefined') {
          if (value) {
            window.localStorage.setItem(key, value)
          } else {
            window.localStorage.removeItem(key)
          }
        }
      } catch (err) {
        throw err
      }
    },
    [key]
  )

  return [getValue, setValue]
}

export default useLocalStorage
