import React, { useState } from 'react'

import styles from './CookieBanner.module.scss'
import Container from './Container'
import useLocalStorage from '../hooks/useLocalStorage'

const CookieBanner = () => {
  const [getConsent, setConsent] = useLocalStorage('cookieConsent')
  const [hasConsent, setHasConsent] = useState(getConsent())

  const onGiveConsent = () => {
    setConsent(true)
    setHasConsent(true)
  }

  if (hasConsent) {
    return null
  }

  return (
    <div className={styles.banner}>
      <Container>
        <div className={styles.content}>
          <p>
            We use cookies to give you a better experience. Check out our{' '}
            <a href="/privacy-policy" target="_blank">
              privacy policy
            </a>{' '}
            for more information
          </p>
          <div>
            <button
              className="btn btn-primary"
              onClick={onGiveConsent}
              type="button"
            >
              I accept
            </button>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default CookieBanner
