import React from 'react'

import styles from './Title.module.scss'

const TitleContainer = ({ children }) => {
  return (
    <div className={styles.titleBackground}>
      <span className={styles.titlePattern} />
      <div className={styles.titleContainer}>{children}</div>
    </div>
  )
}

const Title = ({ children }) => <h1 className={styles.title}>{children}</h1>

const TitleIntro = ({ children }) => (
  <p className={styles.titleIntro}>{children}</p>
)

export { TitleContainer, Title, TitleIntro }
