import React from 'react'

import cn from 'classnames'
import styles from './Container.module.scss'

const Container = ({ size = 'xl', className, children, ...rest }) => {
  const sizeCn = {
    sm: styles.containerSm,
    md: styles.containerMd,
    lg: styles.containerLg,
    xl: styles.containerXl,
  }

  const classNames = cn(sizeCn[size], className)

  return (
    <div className={classNames} {...rest}>
      {children}
    </div>
  )
}

export default Container
